import * as React from "react";
const Doublequote = (props) => (
  <svg
    width={61}
    height={58}
    viewBox="0 0 61 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.12}>
      <path
        d="M46.1143 29.4266C51.7214 29.4266 55.5 33.2652 55.5 38.9527C55.5 44.0708 51.1571 48.3346 45.275 48.3346C38.8321 48.3346 34.0714 43.2166 34.0714 35.3989C34.0714 17.6298 47.2357 10.5222 55.5 9.66797V17.4857C49.8964 18.4805 43.5964 24.0238 43.3143 30.1402C43.5964 29.9996 44.7143 29.4266 46.1143 29.4266ZM17.5464 29.4266C23.1464 29.4266 26.9286 33.2652 26.9286 38.9527C26.9286 44.0708 22.5857 48.3346 16.7036 48.3346C10.2607 48.3346 5.5 43.2166 5.5 35.3989C5.5 17.6298 18.6643 10.5222 26.9286 9.66797V17.4857C21.325 18.4805 15.025 24.0238 14.7429 30.1402C15.025 29.9996 16.1429 29.4266 17.5464 29.4266Z"
        fill="#0D5F67"
      />
    </g>
  </svg>
);
export default Doublequote;
