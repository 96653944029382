import * as React from "react";
const Loadingsvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
    <circle
      fill="rgba(13 95 103 1)"
      stroke="rgba(13 95 103 1)"
      strokeWidth={15}
      r={15}
      cx={40}
      cy={65}
    >
      <animate
        attributeName="cy"
        calcMode="spline"
        dur={2}
        values="65;135;65;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin={-0.4}
      />
    </circle>
    <circle
      fill="rgba(13 95 103 1)"
      stroke="rgba(13 95 103 1)"
      strokeWidth={15}
      r={15}
      cx={100}
      cy={65}
    >
      <animate
        attributeName="cy"
        calcMode="spline"
        dur={2}
        values="65;135;65;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin={-0.2}
      />
    </circle>
    <circle
      fill="rgba(13 95 103 1)"
      stroke="rgba(13 95 103 1)"
      strokeWidth={15}
      r={15}
      cx={160}
      cy={65}
    >
      <animate
        attributeName="cy"
        calcMode="spline"
        dur={2}
        values="65;135;65;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin={0}
      />
    </circle>
  </svg>
);
export default Loadingsvg;
