import '../Styles/CardStyles.css'; 

const CardView = (props) => {
  return (
    <div className="card-container" data-aos="fade-up">
      <div className="card-content">
        <div className='svg-container'>
        {props.svg}
        </div>
        <div className='desc-container'>
        <p className="card-description">{props.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default CardView;
