import React, { useState } from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import "../Styles/TestimonialsSectionStyles.css";
import { TestimonialSectionController } from "../Controller/TestimonialsSectionController";
import { testimonials } from "../Model/TestimonialsSectionModel";
import TestimonialCard from './../../../../Screens/LandingScreen/LandingScreenComponents/TestimonmialSection/TestimonoialComponent/TestimonialCard';

const TestimonialSectionView = () => {
  const {
    scrollContainerRef,
    activeIndex,
    scrollLeft,
    scrollRight,
    scrollToCard,
  } = TestimonialSectionController(testimonials.length);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (action) => {
    if (isDisabled) return;
    setIsDisabled(true);
    action();

    setTimeout(() => {
      setIsDisabled(false);
    });
  };

  return (
    <div className="testimonial-section-main-container" data-aos="fade-up">
      <p className="testimonial-section-main-title">Testimonials</p>

      <div className="testimonial-section-testinomial-cards-container">
        {/* Left Arrow */}
        <div
          className={`leftandrightarrow ${activeIndex === 0 ? "disabled-arrow" : ""}`}
          onClick={() => activeIndex > 0 && handleClick(scrollLeft)}
        >
          <SlArrowLeft
            size={30}
            color={activeIndex === 0 ? "rgba(200, 200, 200, 1)" : "rgba(13, 95, 103, 1)"}
          />
        </div>

        {/* Scrollable Cards */}
        <div className="testimonial-cards-container-vertiacl-scroll" ref={scrollContainerRef}>
          {testimonials.map((details, index) => (
            <TestimonialCard
              key={index}
              name={details.name}
              grade={details.grade}
              comments={details.comments}
              img={details.img}
            />
          ))}
        </div>

        {/* Right Arrow */}
        <div
          className={`leftandrightarrow ${
            activeIndex === testimonials.length - 1 ? "disabled-arrow" : ""
          }`}
          onClick={() => activeIndex < testimonials.length - 1 && handleClick(scrollRight)}
        >
          <SlArrowRight
            size={30}
            color={
              activeIndex === testimonials.length - 1
                ? "rgba(200, 200, 200, 1)"
                : "rgba(13, 95, 103, 1)"
            }
          />
        </div>
      </div>

      {/* Dots Indicator */}
      <div className="dots-indicator">
        {testimonials.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
            onClick={() => {
              if (isDisabled) return;
              handleClick(() => scrollToCard(index));
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSectionView;
