import testinomial1 from '../../../../new_assets/img/TestimonialImage/testimonial-img1.jpg'
import testinomial2 from '../../../../new_assets/img/TestimonialImage/testimonial-img2.jpg'
import testinomial3 from '../../../../new_assets/img/testimonial.jpg'
import testinomial4 from '../../../../new_assets/img/TestimonialImage/testimonial-img4.jpg'
import testinomial5 from '../../../../new_assets/img/TestimonialImage/testimonial-img5.jpg'

export const testimonials=[
    {name:'Lucas', grade:'7 Grade Student Parent' ,comments:"This app has completely transformed the way I approach learning. It's so much more than just a platform—it's like having a personal tutor with me 24/7. The lessons are well-structured, and I love how interactive the content is. Plus, the progress tracking feature is a game-changer! It really motivates me to keep going and see how far I’ve come. I’ve never felt so confident in my ability to learn new things.", img:testinomial1},
    {name:'Mariana', grade:'8 Grade Student Parent' ,comments:"The lessons are so interactive and tailored to my needs, making it easy to stay engaged. I’ve learned more in the last few weeks than I have in months with other methods. The app’s design is sleek, and I can easily track my progress, which keeps me motivated. It’s the perfect tool for anyone who wants to learn at their own pace!", img:testinomial2},
    {name:'Isabella', grade:'9 Grade Student Parent' ,comments:"Online learning has been a bit of a struggle for my kid, but since we started using this application, the attitude has completely changed. The app’s engaging design and personalized lessons have given the confidence to approach learning with excitement rather than dread. I’ve seen a real improvement in the grades, and even asking for extra lessons! As a parent, it’s been a joy to see my kid enjoy learning again.", img:testinomial3},
    {name:'Eliza', grade:'6 Grade Student Parent' ,comments:"I’ve been using this app for a few months now, and I can confidently say it’s one of the best investments I’ve made for my personal growth. The content is top-notch, and the app is user-friendly, which means I can focus on learning rather than figuring out how to navigate the platform. I appreciate the variety of learning styles offered, from audio to visuals, making it easy to absorb new information in a way that works for me.", img:testinomial4},
    {name:'Karl', grade:'11 Grade Student Parent' ,comments:"This app has exceeded my expectations in every way. The courses are not only informative but also incredibly fun to go through. The ability to learn anytime, anywhere has made it so much easier to fit studying into my busy schedule. The real-time quizzes and challenges help reinforce what I’ve learned, and the instant feedback keeps me on track. I’ve already seen a significant improvement in my knowledge and skills!", img:testinomial5},
]





