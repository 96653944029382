import { useEffect } from 'react';

const LandingScreenController = (setContainerStyle, setOverlay) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 200) {
        setContainerStyle('scrolled-container-a'); // Apply scrolled style
        setOverlay('extend-logo-container'); // Extend logo on scroll
      } else {
        setContainerStyle(''); // Reset styles when at the top
        setOverlay(''); // Reset overlay
      }
    };

    // window.addEventListener('scroll', handleScroll);

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, [setContainerStyle, setOverlay]); // Dependencies
};

export default LandingScreenController;