import * as React from "react";
const FbSvg = (props) => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill={props.color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.75 11.5144C22.7498 9.41226 22.1472 7.35424 21.0136 5.58396C19.8802 3.81367 18.263 2.40524 16.3539 1.52544C14.4448 0.645627 12.3236 0.331272 10.2413 0.619586C8.15908 0.90789 6.20305 1.7868 4.60481 3.15225C3.00656 4.5177 1.83303 6.3125 1.22314 8.32418C0.61326 10.3359 0.59256 12.4802 1.1635 14.5032C1.73444 16.5263 2.8731 18.3435 4.44469 19.7395C6.01627 21.1355 7.95497 22.0521 10.0313 22.3805V14.6943H7.23817V11.5144H10.0313V9.09068C10.0313 6.33427 11.6739 4.81077 14.1856 4.81077C15.0107 4.8226 15.8339 4.89429 16.6487 5.02527V7.7331H15.2609C14.8431 7.67765 14.4203 7.79037 14.0857 8.0465C13.751 8.30262 13.5318 8.68118 13.4761 9.09894C13.4603 9.21592 13.4579 9.33432 13.4688 9.45186V11.5144H16.5167L16.029 14.6943H13.4623V22.3805C16.051 21.9726 18.4091 20.6538 20.1117 18.6617C21.8145 16.6695 22.7501 14.135 22.75 11.5144Z"
      fill={props.color}
    />
  </svg>
);
export default FbSvg;
