import { useState, useEffect, useRef } from 'react';


export const HowItWorksController = () => {
  const [showChild, setShowChild] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const shouldShowChild = window.scrollY > 520;
        if (shouldShowChild) {
          setShowChild(true);
          window.removeEventListener("scroll", handleScroll); 
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { showChild, targetRef };
};
