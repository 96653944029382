import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTrendingCourses  } from "../../../../redux/action";
import { getAllCoursesInLandingPage } from './../Model/CourseOverviewSectionModel';


export const CourseOverviewController = () => {
  const dispatch = useDispatch();
  const {  token, session_details, course, purchased_course_details } = useSelector((state) => state.applicationState);
  const [trendingCourses, setTrendingCoursesState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNetworkError, setIsNetworkError] = useState(false);


  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const trendingCourses = await getAllCoursesInLandingPage(session_details);
        dispatch(setTrendingCourses(trendingCourses));
        setTrendingCoursesState(trendingCourses);
      } catch (error) {
        console.error(error);
        console.log("error===>",error)
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, []);

  return { trendingCourses, isLoading, isNetworkError };
};
