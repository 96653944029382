import * as React from "react";
const ProgressSvg = (props) => (
  <svg
    width={83}
    height={82}
    viewBox="0 0 83 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_407_40)">
      <path
        opacity={0.957}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6563 3.76391C61.1417 3.73723 65.6266 3.76391 70.1114 3.84399C70.4557 4.02364 70.7529 4.26387 71.003 4.56469C71.2705 9.08861 71.3245 13.6264 71.1651 18.178C70.5726 19.2302 69.681 19.5773 68.4903 19.219C68.031 18.9788 67.6797 18.6317 67.4366 18.178C67.2291 16.0002 67.1211 13.8114 67.1124 11.6116C52.8677 28.1437 35.2248 39.7017 14.1837 46.2854C12.1033 46.579 11.2117 45.6981 11.5088 43.6428C11.6907 43.3026 11.9339 43.0091 12.2383 42.762C33.4354 36.2484 51.0512 24.557 65.086 7.68774C62.2762 7.63436 59.4662 7.58096 56.6563 7.52758C54.9402 6.27407 54.9402 5.01952 56.6563 3.76391Z"
        fill="white"
      />
      <path
        opacity={0.972}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.918 75.3536C82.918 75.8874 82.918 76.4213 82.918 76.9551C82.6247 77.3778 82.2465 77.7248 81.7832 77.9961C54.873 78.103 27.9629 78.103 1.05273 77.9961C0.589487 77.7248 0.211231 77.3778 -0.0820312 76.9551C-0.0820312 76.4213 -0.0820312 75.8874 -0.0820312 75.3536C0.211231 74.9309 0.589487 74.5838 1.05273 74.3125C4.56469 74.2325 8.07706 74.2057 11.5898 74.2325C11.5628 66.0643 11.5898 57.8964 11.6709 49.7286C11.9141 49.2748 12.2653 48.9278 12.7246 48.6875C17.2097 48.5807 21.6946 48.5807 26.1797 48.6875C26.703 48.9379 27.0543 49.3383 27.2334 49.8887C27.3145 58.0032 27.3415 66.1177 27.3145 74.2325C29.3679 74.2325 31.4212 74.2325 33.4746 74.2325C33.4475 63.0749 33.4746 51.9173 33.5557 40.7598C33.7288 40.0015 34.1881 39.4943 34.9336 39.2383C39.2566 39.1315 43.5794 39.1315 47.9023 39.2383C48.6479 39.4943 49.1071 40.0015 49.2803 40.7598C49.3613 51.9173 49.3884 63.0749 49.3613 74.2325C51.4148 74.2325 53.468 74.2325 55.5215 74.2325C55.4944 59.8717 55.5215 45.511 55.6025 31.1504C55.7817 30.6 56.133 30.1996 56.6562 29.9493C61.1413 29.8424 65.6263 29.8424 70.1113 29.9493C70.5706 30.1895 70.9219 30.5366 71.165 30.9903C71.2461 45.4042 71.2732 59.8182 71.2461 74.2325C74.7588 74.2057 78.2713 74.2325 81.7832 74.3125C82.2465 74.5838 82.6247 74.9309 82.918 75.3536ZM59.5742 33.7129C62.114 33.7129 64.6536 33.7129 67.1934 33.7129C67.1934 47.2194 67.1934 60.726 67.1934 74.2325C64.6536 74.2325 62.114 74.2325 59.5742 74.2325C59.5742 60.726 59.5742 47.2194 59.5742 33.7129ZM37.5273 43.1621C40.1211 43.1621 42.7148 43.1621 45.3086 43.1621C45.3086 53.519 45.3086 63.8756 45.3086 74.2325C42.7148 74.2325 40.1211 74.2325 37.5273 74.2325C37.5273 63.8756 37.5273 53.519 37.5273 43.1621ZM15.6426 52.4512C18.1823 52.4512 20.722 52.4512 23.2617 52.4512C23.2617 59.7116 23.2617 66.9721 23.2617 74.2325C20.722 74.2325 18.1823 74.2325 15.6426 74.2325C15.6426 66.9721 15.6426 59.7116 15.6426 52.4512Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_407_40">
        <rect width={83} height={82} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ProgressSvg;
