import { useEffect, useRef, useState } from "react";

export const TestimonialSectionController = (testimonialsLength) => {
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  
  const updateCardDimensions = () => {
    const cardElement = document.querySelector(".testimonial-card");
    if (cardElement) {
      setCardWidth(cardElement.offsetWidth); 
    }
  };


  const scrollToCard = (index) => {
    const container = scrollContainerRef.current;
    if (container && cardWidth > 0) {
      container.scrollTo({
        left: index * cardWidth,
        behavior: "smooth",
      });

      
      setTimeout(() => {
        setActiveIndex(index);
      }, 500);
     }
  };

  
  const scrollLeft = () => {
    if (activeIndex > 0) {
      scrollToCard(activeIndex - 1);
    }
  };

  const scrollRight = () => {
    if (activeIndex < testimonialsLength - 1) {
      scrollToCard(activeIndex + 1);
    }
  };

 
  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container && cardWidth > 0) {
        const newIndex = Math.round(container.scrollLeft / cardWidth);
        if (newIndex !== activeIndex) {
          setActiveIndex(newIndex); 
        }
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [cardWidth, activeIndex]);

  
  useEffect(() => {
    updateCardDimensions();
    window.addEventListener("resize", updateCardDimensions);

    return () => {
      window.removeEventListener("resize", updateCardDimensions);
    };
  }, []);

  return {
    scrollContainerRef,
    activeIndex,
    scrollLeft,
    scrollRight,
    scrollToCard,
  };
};
