import CollabrativeLearning from '../../../../new_assets/Svg/Collabrative';
import StrengthSvg from '../../../../new_assets/Svg/StrengthSvg';
import ContentSvg from '../../../../new_assets/Svg/ContentSvg';
import ProgressSvg from '../../../../new_assets/Svg/ProgressSvg';



export const HowItWorksData = [
  { svg: <CollabrativeLearning />, desc: "Accelerating education through collaboration" },
  { svg: <ProgressSvg />, desc: "Self-paced learning environment with personalized growth" },
  { svg: <StrengthSvg />, desc: "Embrace challenges and unlock strengths" },
  { svg: <ContentSvg />, desc: "Tailored resources to boost understanding." },
];
