import React from "react";
import "../Styles/CourseOverviewSectionStytles.css";
import { CourseOverviewController } from './../Controller/CourseOverviewSectionController';
import VideoCardView from "../../../../CommonComponents/VideoCardComponent/View/VideoCardView";

const CourseOverviewSectionView = () => {
  const { trendingCourses, isLoading, isNetworkError } = CourseOverviewController();
  return (
    <div className="course-overview-section-container"  data-aos="fade-up" >
      <p className="course-overview-section-container-main-title" data-aos="fade-up">
      Explore our Courses
      </p>
      <div className="course-overview-section-container-video-wrap-section" data-aos="fade-up">
        {trendingCourses.map((course) => (
          <VideoCardView key={course._id} course={course} />
        
        ))}
      </div>
    </div>
  );
};

export default CourseOverviewSectionView;
