import "./App.css";
import { getSessionMethod } from "../src/api/request/Api_Calls";
import { API_URL } from "../src/api/URL/Api_Route";
import { useDispatch, useSelector } from "react-redux";
import { sessionDetails, LogIn } from "./redux/action";
import { Toaster } from "react-hot-toast";
import { Router_Provider } from "./router_container/routers";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
 const [isRender, setRender] = useState(false);
 const { token } = useSelector((state) => state.applicationState);
 const dispatch = useDispatch();

 useEffect(() => {
    AOS.init({
        duration: 1000, 
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
  initialFetchToken();
 }, []);

 const initialFetchToken = async () => {
  let payload = {
   url: `${API_URL.getSession}`, 
  };
  // getSessionMethod(payload)
  //  .then(async (res) => {
  //   console.log("get_session_details response === ", res.data);
  //   if (res?.status == 200 && res.data?.status) {
  //    dispatch(sessionDetails({ session_details: res.data })); 
  //    dispatch(LogIn({ token: res.data.sub })); 
  //   } else if (res?.status == 409) {
  //    console.log("get_session_details failure res === ", res);
  //   }
  //  })
  //  .catch((err) => {
  //   if (err?.response?.status == 404) {
  //    console.log("get_session_details failure");
  //   } else {
  //    console.log("get_session_details catch error === ", err);
  //   }
  //  });

  setRender(true);
 };

 return (
  <div className="App">
   <Toaster />
   {isRender && <Router_Provider  />}
   {/* token={token} */}
  </div>
 );
}

export default App;
