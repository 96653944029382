import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
// // import Login from "../pages/javascript/Login";
// import Loginpage from "../Web/View/Login_Page_View";
// // import Registration from "../pages/javascript/Registration";
// import RegistrationView from "../Web/View/Registration_Page_View";
// import DialogPageView from "../Web/View/Existing_Session_Alert_Page_View";
// import OTP from "../pages/javascript/Otp";
// import ForgetPassword from "../pages/javascript/ForgetPassword";
// import Page_Not_Found from "../component/javascript/Page_Not_Found";
// import All_Courses from "../pages/javascript/All_Courses";
// import Course_Content from "../pages/javascript/Course_Content";
// import My_Courses from "../pages/javascript/My_Courses";
// import Progress from "../pages/javascript/Progress";
// import Cart_Screen from "../pages/javascript/Cart_Screen";
// import Notification from "../pages/javascript/Notification";
// import Profile from "../pages/javascript/Profile";
// import Edit_Profile from "../pages/javascript/Edit_Profile";
// import Footer from "../component/javascript/Footer";
import { useLayoutEffect } from "react";
// import Search_Screen from "../pages/javascript/Search_Screen";
// import PasswordReset from "../pages/javascript/PasswordReset";
// import VideoPlayer from "../pages/javascript/video";
// import Header_Component from "../component/javascript/Header_Component";
// import Assessments from "../pages/javascript/Assessments";
// import PaypalButton from "../component/javascript/PayPal_Payment";
// // import Payment_Screen from "../component/javascript/PayPal_Payment";
// import Payment_Screen1 from "../component/javascript/RazorPay_Payment";
// // import SupportPage from "../pages/javascript/Support_Page";
// import FAQPage from "../Web/Controller/FAQ_Page_Controller";
// import SupportPage from "../Web/Controller/Support_Page_Controller";
import LandingScreenView from './../ScreenComponents/LandingScreen/Components/View/LandingScreenView';
import SignupScreenView from "../ScreenComponents/AuthScreen/View/SignupScreenView";
// import LandingScreen from './../Screens/LandingScreen/LandingScreen';
import FooterSectionView from './../CommonComponents/FooterComponent/View/FooterSectionView';
import SigninScreenView from "../ScreenComponents/AuthScreen/View/SigninScreenView";

export const Router_Provider = (props) => {
 const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
   window.scrollTo(0, 0);
  }, [pathname]);
  return null;
 };

 console.log("Route.js")
 return (
  <>
  {/* <LandingScreen/> */}
  {/* <LandingScreenView/> */}
   <Router>
    <ScrollToTop />
    {/* <Header_Component element={props.token == null ? "Show" : ""} /> */}
    {props.token != null ? (
     <>
      <Routes>
       <Route path="/" exact element={<LandingScreenView />} />
       {/* <Route path="/allCourse" element={<All_Courses />} />
       <Route path="/courseContent" element={<Course_Content />} />
       <Route path="/myCourses" element={<My_Courses />} />
       <Route path="/progress" element={<Progress />} />
       <Route path="/cart" element={<Cart_Screen />} />
       <Route path="/notification" element={<Notification />} />
       <Route path="/profile" element={<Profile />} />
       <Route path="/editProfile" element={<Edit_Profile />} />
       <Route path="/otpAuth" element={<OTP />} />
       <Route path="/forgetPassword" element={<ForgetPassword />} />
       <Route path="/passwordReset" element={<PasswordReset />} />
       <Route path="/assessment" element={<Assessments />} />
       <Route path="/search/:src" element={<Search_Screen />} />
       <Route path="/payment1" element={<Payment_Screen1 />} />
       <Route path="*" element={<Page_Not_Found />} />
       <Route path="/supportpage" element={<SupportPage />} />
       <Route path="/faqpage" element={<FAQPage />} />
       <Route path="/dialog" element={<DialogPageView />} />
       <Route path="/video" element={<VideoPlayer />} /> */}
      </Routes>
     </>
    ) : (
     <>
      <Routes>
       <Route path="/" exact element={<LandingScreenView />} /> 
       <Route path="/signin" exact element={<SigninScreenView />} />
       <Route path="/signup" exact element={<SignupScreenView />} />
       {/* <Route path="/login" element={<Loginpage />} />
       <Route path="/registration" element={<RegistrationView />} />
       <Route path="/otp" element={<OTP />} />
       <Route path="/forgetPassword" element={<ForgetPassword />} />
       <Route path="/passwordReset" element={<PasswordReset />} />
       <Route path="*" element={<Page_Not_Found />} />
       <Route path="/supportpage" element={<SupportPage />} />
       <Route path="/faqpage" element={<FAQPage />} />
       <Route path="/dialog" element={<DialogPageView />} /> */}
      </Routes>
     </>
    )}
    <FooterSectionView />
   </Router>
  </>
 );
};
