import Doublequote from "../../../../../new_assets/Svg/DoublequoteSvg";
import Testimonial from '../../../../../new_assets/img/aaa.jpg';
import './TestimonialCards.css'

const TestimonialCard = (props) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-text">
        {/* <div className="testimonial-header">
          <p className="testimonial-name">
             {props.name} 
          </p>
        </div> */}
        <div className="testimonial-body">
        <div className="testimonial-header">
          <p className="testimonial-name">
            {props.name}
            {/* <br /> */}
          </p>
        </div>
          <div className="testimonial-quote" >
         
            <p>
              {props.comments}
              {/* "The impact e-Learning has had on my son's education is truly commendable. His grades in Mathematics have seen a significant jump, and the best part is, he genuinely enjoys learning now. The progress we've witnessed is beyond what we hoped for. Thank you for making a difference!" */}
            </p>
          </div>
          <div className="testimonial-icon">
            <Doublequote />
          </div>
        </div>
      </div>

      {/* Right Section - Image */}
      {/* <div className="testimonial-image">
        <img
          // src={Testimonial}
          src =  {props.img}
          alt="Testimonial"
          className="testimonial-img"
        />
      </div> */}
    </div>
  );
};

export default TestimonialCard;
