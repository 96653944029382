import { useState } from 'react';

const LandingScreenModel = () => {
  const [containerStyle, setContainerStyle] = useState(''); 
  const [overlay, setOverlay] = useState(''); 

  return {
    containerStyle,
    setContainerStyle,
    overlay,
    setOverlay,
  };
};

export default LandingScreenModel;