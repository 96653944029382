import Loadingsvg from '../../../new_assets/Svg/Loading';
import '../Styles/VideoCardStyles.css';


const VideoCardView = ({ course }) => {

    return (
        <div 
            className={`explore-courses-video-card ${!course ? 'loading' : ''}`} 
            data-aos="fade-up"
        >
            {course ? (
                <>
                    <img 
                        crossOrigin="anonymous" 
                        src={course.thumnail_image} 
                        alt={course.CourseName} 
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }} 
                    />
                    <div className="overlay-wrapper">
                        <div className='text-overlay'>{course.CourseName}</div>
                    </div>
                </>
            ) : (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:"100%",backgroundColor:"rgba(0,0,0,0.5)"}}>
                    <Loadingsvg/>
                </div>
            )}
        </div>
    );
};

export default VideoCardView;