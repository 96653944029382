
import '../Styles/HowItWorksSectionStyles.css';
import { HowItWorksData } from './../Model/HowItWorksSectionModel';
import { HowItWorksController } from './../Controller/HowItWorksSectionController';
// import Card from './../../../../Screens/LandingScreen/LandingScreenComponents/HowCognitiveEdComponent/CardComponent/Card';
import SemicircleView from './../../../../Screens/Semicirlceview';
import CardView from '../../../../CommonComponents/CardComponent/View/CardView';


const HowItWorksSectionView = () => {
  const { showChild, targetRef } = HowItWorksController();

  return (
    <div className="how-it-works-section-container">
      <p className="how-it-works-section-main-title" data-aos="fade-up">
        Our Mission
      </p>
      <div className="how-it-works-container-cards-section" data-aos="fade-up">
        {HowItWorksData.map((item, index) => (
          <CardView key={index} svg={item.svg} desc={item.desc} />
        ))}
      </div>

      <div ref={targetRef} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {showChild && <SemicircleView />}
      </div>
    </div>
  );
};

export default HowItWorksSectionView;
