import { API_URL } from "../../../../api/URL/Api_Route";
import { PostMethod } from "../../../../api/request/Api_Calls";
import RunTimeConstant from "../../../../config/RuntimeConfig";



export const getAllCoursesInLandingPage = async (sessionDetails) => {
  const payload = {
    url: API_URL.trendingCourse,
    data: { cc: sessionDetails?.cc, limit: 4 },
  };

  try {
    const response = await PostMethod(payload);
    if (response.success) {
      console.log("jjjjjjjjjjj",response.data)
      return response.data.map((ele) => ({
        ...ele,
        thumnail_image: `${RunTimeConstant.SERVICEURL.CS}assert/image?id=${encodeURIComponent(
          ele.AssetPath + ele.Image
        )}`,
      }));
    } else {
      console.log("12345")
      throw new Error(response.message);
    }
  } catch (error) {
    throw error;
  }
};
