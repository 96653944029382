import '../Styles/GetStartedSectionStyles.css';
import { getStartedSetionContent } from './../Model/GetStartedSectionModel';

const GetStartedSectionView = () => {
  return (
    <div className="get-started-main-container" data-aos="fade-up">
      <p className="get-started-main-title">{getStartedSetionContent.title}</p>
      <p className="get-started-main-subtext">{getStartedSetionContent.subtext}</p>
      <div className="get-started-container-button" >{getStartedSetionContent.buttonText}</div>
    </div>
  );
};

export default GetStartedSectionView;
